<template>
  
  
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
            {{user.name}}
            </p>
            <span class="user-status">{{typeuser }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badgez
            :src="user.profile_photo_url"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
<router-link  :to="{ name: 'profile' }">
<b-dropdown-item link-class="d-flex align-items-center">
          
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

</router-link>
        
      


        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="ConfirmLogout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>


</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'


export default  {
data() {
  return {
    user: {},
  }
},



  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },


  async mounted() {

       this.getdata();
    await this.delay(10000);




  },
  computed: {
    typeuser() {

      if(this.user.type == 1) {
      return "Reseller";
      }else {
  return "User";
      }
    }
  },









    methods: {
     delay (ms){new Promise(res => setTimeout(res, ms))},

     getdata()   {

              var data =  this.$store.getters['getuser'];

              this.user = data


     },
    ConfirmLogout(){
       this.$swal({
        title: 'Are you sure?',
        text: "Are you sure you want to logout of the system!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, logout!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
        var logout =  await this.$store.dispatch('userlogout').then(result => {


 console.log(logout);
            this.$router.push({ name: 'login' });
          this.$swal({
            icon: 'success',
            title: 'You Have Been Logged Out Successfully.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        });
          
      //   if (logout == true) {
         
      //   }else {

      
      //   this.$swal({
      //   title: 'Something Went Wrong, Please Try again.',
      
      //   icon: 'error',
      //   customClass: {
      //     confirmButton: 'btn btn-primary',
      //   },
      //   buttonsStyling: false,
      // })
      //   }
             
        }
      });
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
