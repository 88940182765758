export default [
  {
    title: 'Home',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Wallet Deposit',
    icon: 'CreditCardIcon',
    children: [
      {
        title: 'Bank Account',
        route: 'depositbank',
      },
      {
        title: 'Other Methods',
        route: 'depositmethod',
      },
      {
        title: 'Transaction',
        route: 'wallettrans',
      },
    ],
  },
 
  {
    header: 'Services',
  },
  {
    title: 'Buy Airtime',
    route: 'buy-airtime',
    icon: 'SmartphoneIcon',
  },
  {
    title: 'Buy Data',
    route: 'buy-data',
    icon: 'WifiIcon',
  },
  {
    title: 'Cable Tv',
    route: 'cable-tv',
    icon: 'MonitorIcon',
  },
  {
    title: 'Electricity',
    route: 'electricity',
    icon: 'ZapIcon',
  },
  {
    title: 'Showmax Voucher',

    route: 'showmax',
  
  
    icon: 'GiftIcon',
  },



  {
    title: 'Education',
    route: 'education',
 

    icon: 'BookIcon',
  },
  
  {
    header: 'Account',
  },
  {
    title: 'Transaction History',
    route: 'history',
    icon: 'ActivityIcon',
  },
  {
    title: 'Referral Earnings',
    route: 'my-referral-earn',
    icon: 'RefreshCwIcon',
  },

  {
    title: 'My Referrals',
    route: 'my-referrals',
    icon: 'UsersIcon',
  },
  {
    title: 'Profile',
    route: 'profile',
    icon: 'UserIcon',
  },
  {
    title: 'Security',
    route: 'security',
    icon: 'SettingsIcon',
  },
  {
    title: 'Lordtech Support',
    route: 'support',
    icon: 'LifeBuoyIcon',
  },


]
